import React, { useEffect } from "react"
import SEO from "../components/atoms/SEO"

import Footer from "../components/molecules/Footer"

import "../components/strongertogether.scss"

const StrongerTogetherPage = () => {

  // domready equivalent
  useEffect(() => {

    const totalSlots = 12; // exclude the 13th tile since on mobile this gets screwed up if we swap it
    const repeatSwapNTimesPerInterval = 2;
    const swapInterval = 10000;

    /**
     * Generates a random arbitrary number.
     * 3rd arg "not" guarantees the number returned will not be that number.
     */
    function getRandomArbitrary(min, max, not) {
      let x;
      let randomArbitrary = function(min, max) {
        return Math.round(Math.random() * (max - min) + min);
      }
      if (not && not === Math.round(not)) {
        /**
         * Keep generating random numbers until it generates something other than "not".
         */
        do {
          x = randomArbitrary(min, max);
        } while (x === not);
        return x;
      }
      return randomArbitrary(min, max);
    }

    function findSlot(slot) {
      return [...document.querySelectorAll('.tiles div')].filter(function(ele) {
        return getComputedStyle(ele)['gridArea'] ===
          'tile' + slot + ' / ' + 
          'tile' + slot + ' / ' + 
          'tile' + slot + ' / ' + 
          'tile' + slot;
      });
    }

    function swap() {

      // console.log('swapping');
      
      let sourcePhotoId = getRandomArbitrary(1, totalSlots);
      let targetPhotoId = getRandomArbitrary(1, totalSlots, sourcePhotoId);

      // grab the elements by the grid-area defined.  
      // NOTE after swapping continuously, these elements will (obviously)
      // have a different class # than their grid-area.
      let source = findSlot(sourcePhotoId)[0];
      let target = findSlot(targetPhotoId)[0];

      source.style.gridArea = 'tile' + targetPhotoId;
      target.style.gridArea = 'tile' + sourcePhotoId;

    }

    setInterval(function() { Array(repeatSwapNTimesPerInterval).fill().map(swap); }, swapInterval);
  }, [])

  let goHome = () => {
    document.location.href = '/';
  }

  return (
    <>
      <div>
        <main>
          <SEO title="Stronger Together" keywords={[ `diversity`, `inclusion`, `DEI` ]} />
          <section className="tiles">

            <div className="logo" onClick={goHome}>
              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="1536.895" height="561.894" viewBox="0 0 1536.895 561.894">
                <g id="Layer_1" data-name="Layer 1" transform="translate(0)">
                  <path id="Path_2" data-name="Path 2" d="M174.048,264.166c41.979,7.894,54.61,47.088,54.61,87.117v90.182c0,66.684-41.329,108.292-112.007,108.292H22.011C7.337,549.757,0,540.284,0,523.752V26.5C0,10.06,7.337.68,22.011.68h96.033c70.678,0,108.014,41.515,108.014,108.2v69.006C226.058,215.593,213.612,258.687,174.048,264.166ZM62.6,234.353h53.96c30,0,46.437-15.7,46.437-49.409V121.417c0-38.45-15.324-56.468-46.437-56.468H62.691Zm56.654,62.783H62.691V485.395h56.654c31.3,0,46.437-18.018,46.437-56.468v-82.38c.279-33.714-16.439-49.409-46.437-49.409Z" transform="translate(0 5.635)" fill="#4f3368" />
                  <path id="Path_3" data-name="Path 3" d="M251.279,549.757H232.7c-12.724,0-20.711-6.315-24.7-21.175L149.3,324.256H94.6v199.5c0,16.439-7.987,25.912-22.011,25.912H53.641c-14.674,0-22.011-9.288-22.011-25.912V26.5C31.63,10.06,38.967.68,53.641.68H148.56c70.678,0,113.4,35.292,113.4,116.094v91.76c0,54.889-20.061,88.6-54.7,105.042l62.691,206.368C274.591,537.126,267.254,549.757,251.279,549.757ZM94.6,260.266h51.36c34.642,0,53.31-16.439,53.31-65.012V130.891c0-48.666-18.575-65.012-53.31-65.012H94.6Z" transform="translate(262.134 5.635)" fill="#4f3368" />
                  <path id="Path_4" data-name="Path 4" d="M104.916,549.757H86.341c-14.674,0-22.011-9.287-22.011-25.912V26.5C64.33,10.06,71.667.68,86.341.68h18.575c14.024,0,22.011,9.287,22.011,25.819V523.752C126.928,540.284,118.941,549.757,104.916,549.757Z" transform="translate(533.135 5.635)" fill="#4f3368" />
                  <path id="Path_5" data-name="Path 5" d="M307.624,116.774V433.663c0,80.8-42.63,116.094-113.307,116.094H101.441c-14.674,0-22.011-9.287-22.011-25.912V26.5C79.43,10.06,86.767.68,101.441.68h92.875C265.18.68,307.624,35.972,307.624,116.774ZM191.809,65.785H141.842V484.651h49.967c34.735,0,53.31-16.532,53.31-65.012V130.891C245.119,82.224,226.544,65.785,191.809,65.785Z" transform="translate(658.276 5.635)" fill="#4f3368" />
                  <path id="Path_6" data-name="Path 6" d="M343.492,302.772V445.8c0,82.38-42.63,116.094-113.307,116.094H224.8c-70.678,0-113.307-32.971-113.307-116.094V116.094C111.49,37.15,154.12,0,224.8,0h5.387c70.678,0,113.307,37.15,113.307,116.094v50.152c0,16.532-7.337,25.912-22.011,25.912H302.905c-14.024,0-22.011-9.287-22.011-25.912V130.025c0-48.666-18.575-65.012-53.31-65.012s-53.31,16.439-53.31,65.012V431.4c0,48.667,18.575,65.012,53.31,65.012s53.31-16.439,53.31-65.012v-91.76H246.9c-14.024,0-22.011-8.637-22.011-25.912V302.772c0-17.275,7.987-25.912,22.011-25.912h74.3Q343.492,276.86,343.492,302.772Z" transform="translate(923.973)" fill="#d4145a" />
                  <path id="Path_7" data-name="Path 7" d="M320.821,549.757H166.091c-14.674,0-22.011-9.287-22.011-25.912V26.5C144.08,10.06,151.417.68,166.091.68H318.778c14.674,0,22.011,9.287,22.011,25.819V39.873q0,25.912-22.011,25.912H206.771V238.347h91.3c14.674,0,22.011,9.288,22.011,25.819v14.117c0,16.532-7.337,25.912-22.011,25.912h-91.3V484.652h114.05c14.674,0,22.011,8.544,22.011,25.819v13.281C342.832,540.284,335.5,549.757,320.821,549.757Z" transform="translate(1194.063 5.635)" fill="#4f3368" />
                </g>
              </svg>

            </div>

            {Array(13).fill().map((item, i) => (
              <div key={i} className={`tile-` + (i + 1)}></div>
            ))}
            
            <span className="arrow">
              Scroll<br />
              <svg xmlns="http://www.w3.org/2000/svg" width="286.82" height="34.504" viewBox="0 0 286.82 97.504">
                <path id="Path_66" data-name="Path 66" d="M7482.436-2855.96l128.674,72.433,130.935-72.433" transform="translate(-7468.816 2869.579)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="20"/>
              </svg>
            </span>
          </section>
          
          <section className="addendum">
            <div className="content">
              <p data-sal="slide-up" data-sal-duration="1200" data-sal-easing="ease">
                &ldquo;It is both a business and moral imperative to shift from talk to action in creating an equitable workplace, workforce and marketplace today.&rdquo;
              </p>
              <p className="home-link" onClick={goHome} data-sal="slide-up" data-sal-duration="1200" data-sal-delay="200" data-sal-easing="ease">
                <strong>We are Stronger Together. We are BRIDGE</strong>
              </p>
              <p data-sal="slide-up" data-sal-duration="1200" data-sal-delay="400" data-sal-easing="ease">
                <a href="mailto:sheryl@wearebridge.com?subject=We%20Are%20Bridge" className="btn">Join Us</a>
              </p>
              <p data-sal="slide-up" data-sal-duration="1200" data-sal-delay="600" data-sal-easing="ease">
                <a href="https://twitter.com/@wearebridge_">#WeAreBridge</a>
              </p>
            </div>
          </section>
        </main>
        <Footer siteTitle={`BRIDGE`} />
      </div>
    </>
  )
}

export default StrongerTogetherPage